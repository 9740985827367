/* The side navigation menu */
.sidenav {
  /*margin-top: 105px;*/
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: white; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /*padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  background-color: #444d58;
  /* margin: 0; */
  /* padding: 0 0 0 2px; */
  color: white;
  font-size: 18px;
  position: fixed;
  left: -29px;
  border-radius: 10px;
  height: 51px;
  padding-top: 13px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
/*#main {
  transition: margin-left 0.5s;
  padding: 20px;
}*/

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  /*.sidenav {
    padding-top: 15px;
  }*/
  .sidenav a {
    font-size: 18px;
  }
}

.call_card {
  min-height: 90px;
  border-radius: 9px;
  padding: 5px;
  padding-top: 10px;
}
.call_card_btn {
  border: none;
  padding: 2px;
  margin-right: 2px;
  padding-top: 9px;
  padding-bottom: 0;
  margin-top: 15px;
}

/***overriding yellow lemon colors***/
.bg-hover-yellow-lemon:hover,
.bg-yellow-lemon {
  background: #f6e5a1 !important;
}
.bg-font-yellow-lemon {
  color: #070707 !important;
}
.cont > .desc {
  font-size: 20px;
}
.in_cb_phone {
  font-size: 15px;
  color: hsl(203, 83%, 64%);
}
.in_cb_name {
  font-size: 18px;
  color: black;
}
.incoming_call_box {
  background-color: #fff7de !important;
}
/****out tooltip for incoing ring animation*/
/*.tooltip {
  position: fixed;
}*/
/*.tooltip {
  z-index: 10;
  display: none;
  padding: 14px 20px;
  bottom: 20px;
  left: 0;
  width: 240px;
  line-height: 16px;
  position: absolute;
}*/
.dumClass {
  z-index: 10;
  display: none;
  padding: 14px 12px;
  bottom: 100px;
  right: 20px;
  width: 240px;
  line-height: 16px;
  position: absolute;
  font-size: 25px;
}
.pps-btn-img:hover .dumClass {
  display: inline;
  color: #ffffff;
  border: 1px solid #dca;
  background: #444;
  -moz-border-radius: 11px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
}

.dumClass:after {
  position: absolute;
  display: block;
  content: "";
  border-style: solid;
  border-width: 8px;
  height: 0;
  width: 0;
  border-color: #444 transparent transparent transparent;
  bottom: -16px;
  right: 15px;
}
/********************************************/
