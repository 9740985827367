/*****Dattable pagination stuff***/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px;
  margin-left: 0px;
  display: inline;
  border: 0px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 0px;
}
.dataTables_paginate {
  margin-bottom: 5px !important;
}
/***********************************/

.jquery-notific8-container {
  z-index: 99999 !important;
}


/***DIDlist style*/
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
