/* line background color*/
.ant-table-thead th {
  text-align: left !important;
  background: #fff !important;
  /* border: solid 1px red !important */
  border-top: solid 1px #eef1f5 !important;
  /*border-bottom: solid 1px #eef1f5 !important;*/
  border-bottom: 0 !important;
}
.ant-table-thead > tr > th:last-child {
  border-right: solid 1px #eef1f5 !important;
}
.ant-table-thead > tr > th:first-child {
  border-left: solid 1px #eef1f5 !important;
}
.ant-table-tbody > tr > td {
  border-bottom: solid 1px #eef1f5 !important;
}
.ant-table-tbody > tr > td:last-child {
  border-right: solid 1px #eef1f5 !important;
}
.ant-table-tbody > tr > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
/* .ant-table-tbody > tr:first-child > td{ 
    border-top: solid 1px #cdd8ed !important;
 } */

.ant-table-tbody > tr > td {
  text-align: left !important;
  background-color: #fbfcfd !important;
  padding:0
}
.ant-table-tbody > tr:nth-child(odd) > td {
  text-align: left !important;
  background: #fff !important;
}

/*hover background odd*/
.ant-table-tbody > tr:hover > td {
  background: #eef1f5 !important;
  border-top: solid 1px #f0f0f0 !important;
  border-bottom: solid 1px #f0f0f0 !important;
}
.ant-table-tbody > tr:hover > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
.ant-table-tbody > tr:hover:nth-child(even) > td:last-child {
  border-right: solid 1px #f0f0f0 !important;
}
.ant-table-tbody > tr:hover:nth-child(even):last-child > td {
  border-bottom: solid 1px #f0f0f0 !important;
}
/*hover background even  adc3ed */
.ant-table-tbody > tr:hover:nth-child(odd) > td {
  background: #eef1f5 !important;
  border-top: solid 1px #f0f0f0 !important;
  border-bottom: solid 1px #f0f0f0 !important;
}
.ant-table-tbody > tr:hover:nth-child(odd) > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
.ant-table-tbody > tr:hover:nth-child(odd) > td:last-child {
  border-right: solid 1px #f0f0f0 !important;
}

/*pagination*/
.ant-table-pagination.ant-pagination {
  margin: 20px 0 !important;
}
.ant-pagination-item {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
  margin-left: 10px !important;
}
.ant-pagination-item-link:after,
.ant-pagination-jump-prev:after,
.ant-pagination-jump-next:after {
  background-color: #eef1f5 !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
}
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-left: 10px !important;
}

.ant-pagination-jump-prev:after,
.ant-pagination-jump-next:after {
  color: #999 !important;
}
.ant-pagination-item-link:first-child {
  /* margin-right:10px !important; */
}
.ant-pagination-item a,
.ant-pagination-item-link:after {
  color: #999 !important;
}
.ant-pagination-item-active {
  background-color: #eef1f5 !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
}
.ant-pagination-item-active a {
  color: #999 !important;
}
/*layout style*/
/*submenu*/
.ant-menu-dark {
  background-color: #212943 !important;
}
.ant-menu-inline.ant-menu-sub {
  background: #161633 !important;
}
/*menu title*/
.ant-menu-inline {
  background-color: #212943 !important;
}
.ant-menu-item-selected {
  background-color: #212943 !important;
}
/*menu out */
.ant-layout-sider-children {
  background-color: #212943 !important;
  min-height: 87vh !important;
}
.ant-layout-sider-children ul li {
  color: #adb6d5;
  font-size: 14px;
}
/*end*/
.ant-layout-sider-trigger {
  background: #212943 !important;
}
/* .ant-menu-inline-collapsed {
  background-color:#dfcdeb !important
} 
.ant-menu-inline-collapsed {
   width: 65px  !important; 
}*/
.ant-menu-vertical > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 60px !important;
  height: 60px !important;
}
