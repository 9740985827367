.ant-radio-group .radio {
  /*display:none !important;*/
}
/* The switch - the box around the slider */
.switch_dcid {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch_dcid input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider_dcid {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_dcid:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider_dcid {
  background-color: #2196f3;
}

input:focus + .slider_dcid {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider_dcid:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider_dcid.round {
  border-radius: 34px;
}

.slider_dcid.round:before {
  border-radius: 50%;
}
/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 15px;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: -3px;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider_dcid .on {
  display: block;
}

input:checked + .slider_dcid .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider_dcid.round {
  border-radius: 34px;
}

.slider_dcid.round:before {
  border-radius: 50%;
}
