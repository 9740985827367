/* line background color*/
.floatingLeads * .ant-table-thead th {
  text-align: left !important;
  background: #fff !important;
  /* border: solid 1px red !important */
  border-top: solid 1px #eef1f5 !important;
  /*border-bottom: solid 1px #eef1f5 !important;*/
  border-bottom: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: smaller;
}
.floatingLeads * .ant-table-thead > tr > th:last-child {
  border-right: solid 1px #eef1f5 !important;
}
.floatingLeads * .ant-table-thead > tr > th:first-child {
  border-left: solid 1px #eef1f5 !important;
}
.floatingLeads * .ant-table-tbody > tr > td {
  border-bottom: solid 1px #eef1f5 !important;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: smaller;
}
.floatingLeads * .ant-table-tbody > tr > td:last-child {
  border-right: solid 1px #eef1f5 !important;
}
.floatingLeads * .ant-table-tbody > tr > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
/* .ant-table-tbody > tr:first-child > td{ 
      border-top: solid 1px #cdd8ed !important;
   } */

.floatingLeads * .ant-table-tbody > tr > td {
  text-align: left !important;
  background-color: #fbfcfd !important;
}
.floatingLeads * .ant-table-tbody > tr:nth-child(odd) > td {
  text-align: left !important;
  background: #fff !important;
}

/*hover background odd*/
.floatingLeads * .ant-table-tbody > tr:hover > td {
  background: #eef1f5 !important;
  border-top: solid 1px #f0f0f0 !important;
  border-bottom: solid 1px #f0f0f0 !important;
}
.floatingLeads * .ant-table-tbody > tr:hover > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
.floatingLeads * .ant-table-tbody > tr:hover:nth-child(even) > td:last-child {
  border-right: solid 1px #f0f0f0 !important;
}
.floatingLeads * .ant-table-tbody > tr:hover:nth-child(even):last-child > td {
  border-bottom: solid 1px #f0f0f0 !important;
}
/*hover background even  adc3ed */
.floatingLeads * .ant-table-tbody > tr:hover:nth-child(odd) > td {
  background: #eef1f5 !important;
  border-top: solid 1px #f0f0f0 !important;
  border-bottom: solid 1px #f0f0f0 !important;
}
.floatingLeads * .ant-table-tbody > tr:hover:nth-child(odd) > td:first-child {
  border-left: solid 1px #f0f0f0 !important;
}
.floatingLeads * .ant-table-tbody > tr:hover:nth-child(odd) > td:last-child {
  border-right: solid 1px #f0f0f0 !important;
}

/*pagination*/
.floatingLeads * .ant-table-pagination.ant-pagination {
  margin: 20px 0 !important;
}
.floatingLeads * .floatingLeads * .ant-pagination-item {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
  margin-left: 10px !important;
}
.floatingLeads * .ant-pagination-item-link:after,
.floatingLeads * .ant-pagination-jump-prev:after,
.floatingLeads * .ant-pagination-jump-next:after {
  background-color: #eef1f5 !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
}
.floatingLeads * .ant-pagination-next,
.floatingLeads * .ant-pagination-jump-prev,
.floatingLeads * .ant-pagination-jump-next {
  margin-left: 10px !important;
}

.floatingLeads * .ant-pagination-jump-prev:after,
.floatingLeads * .ant-pagination-jump-next:after {
  color: #999 !important;
}
.floatingLeads * .ant-pagination-item-link:first-child {
  /* margin-right:10px !important; */
}
.floatingLeads * .ant-pagination-item a,
.floatingLeads * .ant-pagination-item-link:after {
  color: #999 !important;
}
.floatingLeads * .ant-pagination-item-active {
  background-color: #eef1f5 !important;
  border-radius: 0 !important;
  border: 1px solid #eef1f5 !important;
}
.floatingLeads * .ant-pagination-item-active a {
  color: #999 !important;
}
/*layout style*/
/*submenu*/
.floatingLeads * .ant-menu-dark {
  background-color: #212943 !important;
}
.floatingLeads * .ant-menu-inline.ant-menu-sub {
  background: #161633 !important;
}
/*menu title*/
.floatingLeads * .ant-menu-inline {
  background-color: #212943 !important;
}
.floatingLeads * .ant-menu-item-selected {
  background-color: #212943 !important;
}
/*menu out */
.floatingLeads * .ant-layout-sider-children {
  background-color: #212943 !important;
  min-height: 87vh !important;
}
.floatingLeads * .ant-layout-sider-children ul li {
  color: #adb6d5;
  font-size: 14px;
}
/*end*/
.floatingLeads * .ant-layout-sider-trigger {
  background: #212943 !important;
}
/* .ant-menu-inline-collapsed {
    background-color:#dfcdeb !important
  } 
  .ant-menu-inline-collapsed {
     width: 65px  !important; 
  }*/
.floatingLeads * .ant-menu-vertical > .ant-menu-item,
.floatingLeads * .ant-menu-inline > .ant-menu-item,
floatingLeads
  > .ant-menu-vertical
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.floatingLeads
  *
  .ant-menu-inline
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  line-height: 60px !important;
  height: 60px !important;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
